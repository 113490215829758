var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"10","offset-lg":"1","sm":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.register)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":"Title","vid":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Title "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"title","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Ads ID","vid":"app_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" App ID"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"app_id","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.app_id),callback:function ($$v) {_vm.$set(_vm.formData, "app_id", $$v)},expression:"formData.app_id"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Banner","vid":"banner","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Banner ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"banner","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.banner),callback:function ($$v) {_vm.$set(_vm.formData, "banner", $$v)},expression:"formData.banner"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Interstitial","vid":"interstitial","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Interstitial ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"interstitial","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.interstitial),callback:function ($$v) {_vm.$set(_vm.formData, "interstitial", $$v)},expression:"formData.interstitial"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Rewared","vid":"rewared","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Rewared ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"rewared","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.rewared),callback:function ($$v) {_vm.$set(_vm.formData, "rewared", $$v)},expression:"formData.rewared"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Native","vid":"native_ads","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Native ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"native_ads","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.native_ads),callback:function ($$v) {_vm.$set(_vm.formData, "native_ads", $$v)},expression:"formData.native_ads"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-3"}),_c('div',{staticClass:"col text-right"},[_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.saveBtnName))]),_vm._v("   "),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.$bvModal.hide('modal-1')}}},[_vm._v("Close")])],1)])],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }